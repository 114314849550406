import React from 'react';
import styled from "styled-components";
import { AppBar, Dialog, DialogTitle, DialogContent, DialogActions, Button, Tooltip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { OnlineEvent, InPersonEvent } from "./data/course";
import { useAppSelector } from "../../app/hooks";
import { API_URL } from "../../app/runtimeConstants";

interface CourseRegisterProps {
    onClose: () => void;
    open: boolean;
    course: OnlineEvent | InPersonEvent;
}
interface EnrollmentCount {
    [eventUid: string]: number;
}
interface EnrollmentStatus {
    [eventUid: string]: string;
}

interface EnrollmentStats {
    [eventUid: string]: { status: string, count: number, loading: boolean; };
}

export default function CourseRegister(props: CourseRegisterProps) {
    const [loading, setLoading] = React.useState(true);
    const [enrollmentStats, setEnrollmentStats] = React.useState(() => {
        let initialValues: EnrollmentStats = {};
        props.course.scheduledEvents.forEach(se => {
            if (new Date(se.start).valueOf() > Date.now()) {
                //event is in future, allow registration
                initialValues[se.uid] = { status: "unknown", count: -1, loading: true };
            }
        });
        return initialValues;
    });
    // const [enrollmentStatus, setEnrollmentStatus] = React.useState<EnrollmentStatus>({}); 
    // const [enrollmentCount, setEnrollmentCount] = React.useState<EnrollmentCount>({});

    const token = useAppSelector(s => s.oidc.user?.access_token);

    //TODO fix this implementation, it is a quickfix implementation that only works for ONE future event (UI needs an accoridion or something for each event and the status and count state variables need to be a lookup object)
    React.useEffect(() => {
        if (props.open && loading && token && Array.isArray(props.course.scheduledEvents) && props.course.scheduledEvents.length > 0) {
            const promises =
                props.course.scheduledEvents.map(e => {
                    const courseUid = e.courseUid;
                    const eventUid = e.uid;
                    return fetch(`${API_URL}/mydata/enrollmentStatus/${courseUid}/${eventUid}`, {
                        headers: { 'Authorization': `Bearer ${token}` },
                        credentials: "include"
                    }).then(response => {
                        return response.text().then(status => {
                            return fetch(`${API_URL}/mydata/enrollmentCount/${courseUid}/${eventUid}`, {
                                headers: { 'Authorization': `Bearer ${token}` },
                                credentials: "include"
                            }).then(response2 => {
                                return response2.text().then(count => {
                                    const stats = { status, count: Number(count), loading: false };
                                    return { [eventUid]: stats };
                                    //let allStats: EnrollmentStats = JSON.parse(JSON.stringify(enrollmentStats));
                                    //allStats[eventUid] = stats;
                                    //setEnrollmentStats(allStats);
                                });
                            });
                        });
                    });
                });
            Promise.all(promises).then(values => {
                const result = values.reduce((accum,cur)=> {
                    return {...accum, ...cur};
                }, {});

                setEnrollmentStats(result);
                setLoading(false);
            });
            
        }
    }, [loading, enrollmentStats, props.course.scheduledEvents, props.open, token]);

    const enroll = (courseUid: string, scheduledEventUid: string) => {
        fetch(`${API_URL}/mydata/enroll/${courseUid}/${scheduledEventUid}`, {
            headers: { 'Authorization': `Bearer ${token}` },
            credentials: "include",
            method: "POST"
        }).then(response => {
            response.text().then(status => {
                const origCount = enrollmentStats[scheduledEventUid].count;
                const newStats = { status, count: origCount + 1, loading: false };
                let allStats: EnrollmentStats = JSON.parse(JSON.stringify(enrollmentStats));
                allStats[scheduledEventUid] = newStats;
                setEnrollmentStats(allStats);
            });
        });
    };

    return (
        <Dialog fullWidth maxWidth="lg" onClose={props.onClose} open={props.open}>
            <DialogTitle></DialogTitle>
            <DialogContent>
                {getEventRegistrationTable(props.course, enrollmentStats, enroll)}
            </DialogContent>
            <DialogActions>
                <Button name="close" onClick={props.onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>

    );
}

function getEventRegistrationRows(course: OnlineEvent | InPersonEvent, eStats: EnrollmentStats, enroll: (courseUid: string, scheduledEventUid: string) => void) {
    //TODO: iterate through eStats instead? that way we don't have to duplicate the future date check
    
    return course.scheduledEvents.map(fe => {
        if (new Date(fe.start).valueOf() > Date.now()) {
            const ec = eStats[fe.uid].count; // ? enrollmentCount[fe.uid] : fe.availableSeats || 0; // disable registration if enrollmentcount is not set
            const es = eStats[fe.uid].status;
            return (
                <>
                    <TableRow key={fe.uid}>
                        <TableCell colSpan={4}>{fe.name}</TableCell>
                        <TableCell>{fe.availableSeats ? fe.availableSeats - ec : ""}</TableCell>
                        <TableCell><Button variant="contained" color="primary" disabled={es !== "notRegistered" && es !== "unknown"} onClick={() => enroll(fe.courseUid, fe.uid)}>Enroll</Button></TableCell>
                    </TableRow>

                    <TableRow key={`${fe.uid}enrollmentStatus`}>
                        {/* <TableCell colSpan={4}>&nbsp;</TableCell> */}
                        <TableCell colSpan={6} align="center">{`You are currently ${es === "enrolled" ? "enrolled" : es === "waitlist" ? "on the waiting list" : es == "unknown" ? "unknown" : "not registered"}.`}</TableCell>
                    </TableRow>

                    {fe.schedule.map(s =>
                        <TableRow key={s.start.toString()}>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell>{s.start.toLocaleString()}</TableCell>
                            <TableCell>{s.end.toLocaleString()}</TableCell>
                            <TableCell>{s.location}</TableCell>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>)}
                </>
            );

        } else {
            return null;
        }
    });
}

function getEventRegistrationTable(course: OnlineEvent | InPersonEvent, eStats: EnrollmentStats, enroll: (courseUid: string, scheduledEventUid: string) => void) {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Session</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>End</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Available Seats</TableCell>
                        <TableCell>&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getEventRegistrationRows(course, eStats, enroll)}
                </TableBody>
            </Table>
        </TableContainer>
    );
}