import React from "react";
import styled from "styled-components";
import { Tooltip, Divider, Card, CardActions, CardContent, CardMedia, Button, Typography, IconButton } from "@material-ui/core";
// import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
// import PrereqIcon from '@material-ui/icons/ReportProblemOutlined';
// import ExpiryIcon from '@material-ui/icons/ReportProblemOutlined';
// import { FileCertificateIcon } from "../../../common/FAIcons";
import { getInfoContentMessages } from "./InfoContentMessages";
import CourseMoreInfo from "./CourseMoreInfo";
import CourseRegister from "./CourseRegister";
import ScormPlayer from "./ScormPlayer";
import CompletionCertificate from "./CompletionCertificate";

import { FileCertificateIcon, CompulsionInfoIcon, ExpiredCourseIcon, ExpiringCourseIcon, PrereqInfoIcon, MoreInfoIcon } from "./CoursesIcons";
import { CourseSummary } from "./coursesAPI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { fetchCourseSummariesAsync } from "./coursesSlice";
import { ConditionalCoursePrerequisite, CoursePrerequisite } from "./data/course";

// <path class="fa-secondary" fill="currentColor" d="M512 128v360.12A23.94 23.94 0 0 1 488 512H224v-91.19a61.78 61.78 0 0 0 35.49-41.11c.38-1.42.72-2.72 1-3.94.66-2.56 1.4-5.39 1.89-7 1.14-1.23 3.12-3.24 4.92-5.06l2.91-2.94A62.5 62.5 0 0 0 286 301.38c-.37-1.41-.72-2.71-1-3.92-.69-2.61-1.46-5.5-1.84-7.16.38-1.68 1.16-4.6 1.86-7.25.31-1.18.65-2.45 1-3.83a62.45 62.45 0 0 0-15.63-59.28l-2.76-2.81c-1.85-1.88-3.9-3.95-5.05-5.2-.49-1.64-1.23-4.51-1.91-7.1q-.47-1.8-1-3.9a61.69 61.69 0 0 0-43.13-43.7h-.11l-3.75-1c-2.44-.66-5.13-1.39-6.75-1.88-1.23-1.18-3.22-3.21-5-5.05-.84-.86-1.73-1.78-2.71-2.77a60.88 60.88 0 0 0-59.47-16.31h-.05l-3.77 1c-2.4.66-5 1.39-6.69 1.79V23.88A23.94 23.94 0 0 1 152 0h232v112a16 16 0 0 0 16 16z" opacity="0.4"></path><path class="fa-primary" fill="currentColor" d="M505 105L407.1 7a24 24 0 0 0-17-7H384v112a16 16 0 0 0 16 16h112v-6.1a23.9 23.9 0 0 0-7-16.9zM255 271.09a30.14 30.14 0 0 0-7.58-28.79c-14.86-15.12-13.42-12.61-18.86-33.3a29.57 29.57 0 0 0-20.71-21c-20.28-5.53-17.84-4.1-32.69-19.21a28.92 28.92 0 0 0-28.28-7.79c-20.32 5.54-17.46 5.53-37.75 0a28.94 28.94 0 0 0-28.28 7.71c-14.91 15.18-12.5 13.7-32.68 19.21A29.53 29.53 0 0 0 27.46 209c-5.46 20.74-4 18.13-18.87 33.27A30.15 30.15 0 0 0 1 271.09c5.45 20.71 5.42 17.79 0 38.41a30.12 30.12 0 0 0 7.58 28.78c14.86 15.11 13.42 12.61 18.88 33.27a29.52 29.52 0 0 0 20.71 21.07c14.3 3.9 11.52 3 15.83 5V512l64-32 64 32V397.63c4.31-2 1.52-1.1 15.84-5a29.53 29.53 0 0 0 20.7-21.07c5.47-20.74 4-18.13 18.88-33.27a30.12 30.12 0 0 0 7.58-28.78c-5.43-20.65-5.44-17.74 0-38.42zM128 352a64 64 0 1 1 64-64 64 64 0 0 1-64 64z"></path>

// interface CourseInfoCardProps {
//     course: CourseType;
//     summary: UserCourseSummary;
//     history?: UserHistoryType;
// }

const SlopedCardMedia = styled(CardMedia)`
    width: 100%;
    height: 80px;
    padding-bottom: 80px;
    clip-path: polygon(0 0, 100% 0%, 100% 50px, 0% 80px);
    opacity: 0.8;
    /* background-color: rgba(0,0,0,0.08); */
`;

const CourseTypeLabel = styled.div`
    position: relative;
    text-align: right;    
    top: -10px;
    padding-right: 0.5rem;
    opacity: 0.8;
`;

const InfoContent = styled(CardContent)`
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;
    position: relative;    
    height: 195px;    
`;

// marginLeft: "auto", marginRight: "auto", marginBottom: "1rem"
const CourseCard = styled(Card)`
    width: 248px;
    margin: 0 1rem 1rem 1rem;
    @media (max-width: 320px) {
        margin-left: 0;
        margin-right: 0;
    }
`;

/*
the purpose of the below max-width calc is to ensure that whenever the flexbox wraps to one card per row that: 
 -the single card margin cause it to center on screen
 -ensure that whenever there are two cards per row that the margins of the card still exist

 when 1rem = 16px, the max-width calc equals 592px
 if the horizontal margins of CourseCard are changed from 1rem, 
 or the page margins are changed from 1rem (when page width is more than needed for 2 horizontal cards),
 then the calc needs to be adjusted

 calc(248px * 2 + 1rem * 6)  -- doesn't seem to work in media query
*/
const CourseCardWrapper = styled.div`
@media (max-width: 592px) {
        margin-left: auto;
        margin-right: auto;
    }
`;

// CoursesFlexContainer is what mutltiple CourseInfoCards should be wrapped with
export const CoursesFlexContainer = styled.div`
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;
    align-content: flex-start;
`;
export default function CourseInfoCard(props: CourseSummary & {inCRG: boolean}) {
    const dispatch = useAppDispatch();
    const authUserName = useAppSelector(state => ((state.auth.user?.profile?.givenName || "") + " " + (state.auth.user?.profile?.surname || "")));
    const userProfile = useAppSelector(state => state.auth.user?.profile);

    const [moreInfoOpen, setMoreInfoOpen] = React.useState(false);
    const [courseRegisterOpen, setCourseRegisterOpen] = React.useState(false);
    const [scormPlayerOpen, setScormPlayerOpen] = React.useState(false);
    const [completionCertificateOpen, setCompletionCertificateOpen] = React.useState(false);
    const icm = getInfoContentMessages(props.course, props.userCourse);
    const handleMoreInfoClick = () => {
        setMoreInfoOpen(true);
    };
    const handleMoreInfoClose = () => {
        setMoreInfoOpen(false);
    };
    const handleCourseRegisterClick = () => {
        setCourseRegisterOpen(true);
    };
    const handleCourseRegisterClose = () => {
        setCourseRegisterOpen(false);
    };
    const handleScormPlayerOpen = () => setScormPlayerOpen(true);
    const handleScormPlayerClose = () => {
        setScormPlayerOpen(false);
        setTimeout(() => dispatch(fetchCourseSummariesAsync(userProfile)), 500);
        // dispatch(fetchCourseSummariesAsync());
    };

    const handleCompletionCertificateOpen = () => setCompletionCertificateOpen(true);
    const handleCompletionCertificateClose = () => setCompletionCertificateOpen(false);

    const handleCourseActionClick = () => {
        if (props.course.details.kind === "OnlineEvent" || props.course.details.kind === "InPersonEvent")
            handleCourseRegisterClick();
        else if (props.course.details.kind === "OnlineSelfPacedModule")
            handleScormPlayerOpen();
    };

    const hasFutureEvents = (props.course.details.kind === "OnlineEvent" || props.course.details.kind === "InPersonEvent" ) 
        ? props.course.details.scheduledEvents.filter(se => new Date(se.start).valueOf() > Date.now()).length > 0 
            ? true 
            : false 
        : false;
        

    const courseActionDisabled = !props.course.prereqsSatisfied || ((props.course.details.kind === "OnlineEvent" || props.course.details.kind === "InPersonEvent") && !hasFutureEvents);
    let courseActionDisabledReason = "";
    if (!props.course.prereqsSatisfied)
        courseActionDisabledReason += "Prerequisite course(s) must be completed first. ";
    if (((props.course.details.kind === "OnlineEvent" || props.course.details.kind === "InPersonEvent") && !hasFutureEvents))
        courseActionDisabledReason += "There are no events available for registration. ";


    const courseUrl = props.course.details.kind === "OnlineSelfPacedModule" ? props.course.details.url : "";
    const userCanResetCourse = (props.userCourse && props.userCourse.status === "Completed" && props.course.details.kind === "OnlineSelfPacedModule" && props.course.details.allowReview) || false;

    const courseCertificateName = typeof props.course.certificate === "object" ? props.course.certificate.name : props.course.name;

    return (
        <CourseCardWrapper key={props.course.uid}>
            <CourseMoreInfo
                messages={icm}
                onClose={handleMoreInfoClose}
                open={moreInfoOpen}
                course={props.course}
                userCourse={props.userCourse}
                history={props.history}
            />
            {
                (props.course.details.kind === "OnlineEvent" || props.course.details.kind === "InPersonEvent") &&
                <CourseRegister onClose={handleCourseRegisterClose} open={courseRegisterOpen} course={props.course.details} />
            }
            {
                props.course.details.kind === "OnlineSelfPacedModule" &&
                <ScormPlayer
                    courseName={props.course.name}
                    courseId={props.course.uid}
                    userCourseStatus={props.userCourse.status}
                    open={scormPlayerOpen}
                    courseResetAllowed={userCanResetCourse}
                    onClose={handleScormPlayerClose}
                    url={courseUrl} />
            }
            {
                props.userCourse.latestDateCompleted &&
                <CompletionCertificate open={completionCertificateOpen} onClose={handleCompletionCertificateClose} studentName={authUserName} courseName={courseCertificateName} completionDate={new Date(props.userCourse.latestDateCompleted).toLocaleDateString()} />
            }
            <CourseCard>
                <SlopedCardMedia
                    image={props.course.header.imgUrl}
                />
                {!props.inCRG && <CourseTypeLabel>
                    <Typography variant="caption" gutterBottom>{props.userCourse.compulsion || "Unknown"}</Typography>
                    <Tooltip title={icm.compulsion}><CompulsionInfoIcon /></Tooltip>
                </CourseTypeLabel>}
                <InfoContent>


                    <Typography variant="h5" component="h2">
                        {props.course.name}
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom>{icm.courseTypeSubtitle}</Typography>
                    <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                        {<>{icm.courseStatus.split("\n").map((e, i) => <span key={i}>{e.trim()}<br /></span>)}</>}
                    </Typography>

                    {icm.courseExpiration &&
                        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                            {icm.courseExpiration.hasExpired ? <ExpiredCourseIcon /> : <ExpiringCourseIcon />}
                            {icm.courseExpiration.message}
                        </Typography>}
                    {icm.coursePrereqs &&
                        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                            <Tooltip title={icm.coursePrereqs}>
                                <PrereqInfoIcon />
                            </Tooltip>
                            This course has prerequisites
                        </Typography>}
                    {icm.upcomingEvents &&
                        <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
                            {icm.upcomingEvents}
                        </Typography>
                    }
                </InfoContent>
                <Divider light />
                <CardActions style={{ justifyContent: "space-between" }}>
                    <Tooltip title={courseActionDisabledReason}>
                        <span>
                            <Button color="primary" onClick={handleCourseActionClick} disabled={courseActionDisabled}>{icm.actionLabel}</Button>
                        </span>
                    </Tooltip>
                    <div>
                        {props.userCourse.latestDateCompleted &&
                            <Tooltip title="Open Most Recent Completion Certificate">
                                <IconButton onClick={handleCompletionCertificateOpen}><FileCertificateIcon /></IconButton>
                            </Tooltip>}
                        <Tooltip title="More Info">
                            <IconButton onClick={handleMoreInfoClick}><MoreInfoIcon /></IconButton>
                        </Tooltip>
                    </div>
                </CardActions>
            </CourseCard>
        </CourseCardWrapper>
    );
}


