import React from 'react';
import Grid from '@material-ui/core/Grid';

function Downloads() {

    return (
        <Grid container spacing={5}>
            <Grid item xs={12}>
                <h4>Downloads</h4>
                <p><a href="/Downloads/RCRACrseMemoCY23.pdf" target="_blank">RCRA Course Memo CY23</a></p>
                <p><a href="/Downloads/CrseMemoEPOC_FY23.pdf" target="_blank">EPOC Course Memo FY23</a></p>
            </Grid>
        </Grid>
    );
}

export default Downloads;