import { Course } from "./data/course";
import { UserCourse } from "./data/userCourse";

export interface InfoContentMessages {
    actionLabel: string;
    courseTypeSubtitle: string;
    courseStatus: string;
    compulsion: string;
    courseExpiration?: { hasExpired: boolean; message: string };
    coursePrereqs?: string;
    upcomingEvents?: string;
}
export function getInfoContentMessages(course: Course, userCourse: UserCourse): InfoContentMessages {
    const icm: InfoContentMessages = {
        actionLabel: getActionLabel(course),
        courseTypeSubtitle: getCourseTypeSubtitle(course),
        courseStatus: getCourseStatusMessage(course, userCourse),
        compulsion: getCourseCompulsionMessage(userCourse),
        courseExpiration: getCourseExpiryMessage(userCourse),
        coursePrereqs: getCoursePrereqMessage(course),
        upcomingEvents: getUpcomingEventsMessage(course)
    };

    return icm;
}

function getActionLabel(course: Course) {
    switch(course.details.kind) {
        case "InPersonAdHoc": return "Open";
        case "InPersonEvent":
        case "OnlineEvent":
                return "Register";
        default: return "Launch";
    }
}

function getUpcomingEventsMessage(course: Course) {
    if (course.details.kind === "OnlineEvent" || course.details.kind === "InPersonEvent") {
        const hasFutureEvents = (course.details.kind === "OnlineEvent" || course.details.kind === "InPersonEvent" ) 
        ? course.details.scheduledEvents.filter(se => new Date(se.start).valueOf() > Date.now()).length > 0 
            ? true 
            : false 
        : false;

        if(hasFutureEvents) {
            const futureEvents = course.details.scheduledEvents.filter(f => new Date(f.start).valueOf() > Date.now());

            return `Next event available for registration starts on ${futureEvents.sort((a,b) => new Date(a.start).valueOf() - new Date(b.start).valueOf())[0].start}`; // .toLocaleDateString()
        } else {
            return "This course is not currently scheduled";
        }
    }
    return undefined;
}

export function getCourseTypeSubtitle(course: Course) {
    switch (course.details.kind) {
        case "InPersonAdHoc": return "Ad-Hoc Training";
        case "InPersonEvent": return "In-person Event";
        case "OnlineDocReview": return "Online Document Review";
        case "OnlineEvent": return "Online Event";
        case "OnlineSelfPacedModule": return "Online Course";
        default: return "Unknown Course Type";
    }
}
function getCourseCompulsionMessage(userCourse: UserCourse) {
    let text = "";
    if (userCourse) {
        switch (userCourse.compulsion) {
            case "Optional":
                text = "This course is optional."
                break;
            case "Recommended":
                text = "This course is recommended. For details, see the Requirement Level tab after clicking the More Info button below."
                break;
            case "Required":
                text = "This course is required. For details, see the Requirement Level tab after clicking the More Info button below."
                break;
        }
    }
    return text;
}

function getCoursePrereqMessage(course: Course) : string | undefined {
    const hasPrereqs =
        (course.recommendedPrereqs && course.recommendedPrereqs.length > 0)
        ||
        (course.requiredPrereqs && course.requiredPrereqs.length > 0);
    return hasPrereqs
        ? "This course has prerequisites. For details, see the Prerequisites tab after clicking the More Info button below."
        : undefined;
}

function getCourseExpiryMessage(userCourse: UserCourse) {
    if(userCourse) {
        if(userCourse.expiryDate && new Date(userCourse.expiryDate) < new Date()) {
            // expired
            return {
                hasExpired: true,
                message: `Expired on ${new Date(userCourse.expiryDate).toLocaleDateString()}`
            };
        }
        if(userCourse.expiryDate && new Date(userCourse.expiryDate) > new Date()) {
            // expired
            return {
                hasExpired: false,
                message: `Will expire on ${new Date(userCourse.expiryDate).toLocaleDateString()}`
            };
        }
    }
}

function getCourseStatusMessage(course: Course, userCourse: UserCourse) {
    let text = "";
    if (userCourse) {
        switch (userCourse.status) {                        
            case "In Progress":
                text = "Course started";
                if (userCourse.latestDateCompleted)
                    text += `\nLast completed on ${new Date(userCourse.latestDateCompleted).toLocaleDateString()}`;                
                break;
            case "Completed":
            case "Expired":
                if (userCourse.latestDateCompleted)
                    text = `Last completed on ${new Date(userCourse.latestDateCompleted).toLocaleDateString()}`;
                else
                    text = "Course previously completed";
                break;            
            case "N/A":
                text = "";
                break;
            case "Not Started":
                if(course.details.kind === "InPersonEvent" || course.details.kind === "OnlineEvent")
                    text = "You have not attended previously";
                else
                    text = "You have not started this course";
                break;
            case "Registered":
                text = "You are registered for this course";
                break;
        }
    }
    return text;
}